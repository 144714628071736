.k-product-card {
  .mat-card-header-text {
    width: 100% !important;
    height: 100%;
    margin-top: 12px;
  }

}

.mat-tooltip-panel {

}

.k-product-tooltip {
  width: 260px !important;
  height: 150px !important;
  font-size: 16px;
  font-weight: bold;
  background: rgba(51, 51, 51, 0.8);
  color: #f7f7f7 !important;
}

.k-cart-panel {
  width: 300px;
  max-width: 300px !important;
}

.tm-paging-item {
  list-style: none;
  display: inline-block;
}

.tm-paging-link {
  margin: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #2F956D;
  background-color: transparent;
}

.k-type-filter {
  .k-type-filter-item {
    color: #f9d607;
    cursor: pointer;
    list-style: none;
    display: inline-block;

    .ProdTypeActive {
      border-bottom: 4px solid white;
      //margin-bottom: -2px !important;
      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 2px;
      //background-color: #f9d607 !important;
      color: white;
    }

    a {
      color: #f9d607;
      text-decoration: none;
      list-style: none;
      display: grid;
      //margin-bottom: 2px !important;
      height: calc(100% - 4px);

      &:hover {
        //border-bottom: 2px solid black;
        //margin-bottom: 0 !important;
        color: white;
      }

      img {
        margin: auto;
        width: 50px;
        height: 50px;
      }

      span {
        font-size: 12px;
        text-align: center;
        font-weight: bold;
        margin-bottom: 2px !important;
      }
    }
  }

  .active {
    color: white !important;
    background-color: #f9d607;

    a {
      color: white !important;
    }
  }
}
