.k-admin-container {
  .mat-tab-label-container {

    .mat-tab-label-content {
      color: black;
      font-weight: bold;
    }

    .mat-tab-label-active {
      border-bottom: 2px solid white;
    }

  }
}

.k-admin-settings {
  .mat-tab-label-container {
    background-color: #7986cb !important;
  }
}

.k-table {
  //width: 90%;
  //margin: 30px auto auto;

}

.k-title {
  width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;
  text-align: center;
}

.k-table {
  margin-left: 10px;
  margin-right: 10px;
  width: 100%;

  td, th {
    padding-left: 15px !important;
    padding-right: 15px !important;
    border-right: 1px solid black;
  }
}

.k-prod-desc {
  text-align: left !important;
  white-space: unset !important;
  flex: 0 0 40% !important;
  width: 40% !important;
  overflow-wrap: break-word;
  word-wrap: break-word;

  word-break: break-word;

  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.k-actions-column {
  width: 200px;

  .k-edit-button {
    margin-right: 5px;
  }
}

.k-prod-filter {
  margin-left: 30px;
}

tr.k-detail-row {
  height: 0;
}

tr.k-element-row:hover {
  cursor: pointer;
  background: whitesmoke;
}

tr.k-element-row:not(.k-expanded-row):active {
  background: #efefef;
}

.k-element-row td {
  border-bottom-width: 0;
}

.k-expanded {
  margin: auto;
  overflow: hidden;
  display: block;
  background-color: #80808014;
}

.k-expanded-container {
  padding: 10px 20px;
}

.k-img-upload {
  img {
    width: 100px;
    height: 100px;
  }

  mat-progress-bar {
    margin-top: 12px;
  }
}

.k-table-parent {
  width: 100%;
  min-height: calc(100vh - 50px);

  .k-table-spinner {
    position: fixed;
    top: 50%;
    left: 50%;
  }
}
