.k-cart-table {
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
  table-layout: fixed;

  tr {
    padding-bottom: 8px;
    height: 50px;
  }

  td {
    word-wrap: break-word;

  }

  .k-cart-entry {
    border-bottom: 1px solid #afaaaa;

    td {
      padding-bottom: 8px;
      padding-top: 8px;
    }

    .k-cart-entry-image {
      width: 36px;

      img {
        height: 32px;
        width: 32px;
      }
    }

    .k-cart-entry-name {

        .k-cart-product-name {
          font-size: 14px;
        }

        .k-cart-product-details {
          font-size: 10px;
        }
      span {
        //margin: auto 10px;
      }
    }

    .k-cart-product-quantity {
      width: 100px;
      text-align: center;

      span {
        font-size: 16px;
        font-weight: bold;
        text-align: center;
      }

      button {
        width: 30px;
        height: 30px;
      }
    }

    .k-cart-entry-price {
      width: 64px;
      //float: right;
      font-size: 16px;
      //margin: auto;
      font-weight: bold;
    }

    .k-cart-entry-delete {
      width: 32px;

      span {
        display: block;
        width: 30px;
        font-size: 20px !important;
        line-height: 21px !important;
        border-radius: 100%;
        color: #000;
        font-weight: 700;
        text-align: center;
        //border: 2px solid currentColor;
        cursor: pointer;

        &:hover {
          color: #ccc;
          font-size: 24px !important;
        }
      }
    }
  }
}

.delivery-row {
  padding-top: 20px;
  padding-bottom: 20px;
  margin: 0;
  font-weight: bold;

}

.k-fee-entry {
  padding: 10px 35px;
  display: flex;
  justify-content: space-between;

  span {
    margin-left: 135px;
  }

  div {
    font-size: 16px;
    font-weight: bold;
  }
}

.mat-badge-content {
  background-color: white;
  color: black;
}

.mat-snack-bar-container {
  background: #097141;
  color: white;
}
