/* You can add global styles to this file, and also import other style files */
@import "assets/css/admin-panel";
@import "assets/css/home-panel";
@import "assets/css/k-cart";


$k-yellow: #f9d607;
html, body {
  height: 100%;
  overflow-x: hidden;
}

body, html {
}

.admin-body {
  overflow-x: auto !important;
}

html {
  /* width */
  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $k-yellow;
    width: 15px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: black;
    width: 15px;
  }
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.k-container {
  min-height: calc(100vh - 180px);
  background: white;
  position: relative;
}

.mat-drawer-inner-container {
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  & {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.mat-drawer-content {
  overflow-x: hidden !important;
}


.k-parent {
  position: relative;
  padding-bottom: 120px;
  padding-top: 130px;

  .k-top-toolbar {
    position: fixed;
    bottom: 0;
    z-index: 5;
    height: 50px;
    background-color: #333333;
    box-shadow: 0 0 6px #6b6868;

    .bottom-segment {
      height: 100%;
      padding: 10px 8px;
      font-size: 14px;

      &:first-of-type {
        border-right: 2px solid white;
      }

      img {
        width: 32px;
        height: 32px;
        margin-right: 8px;
      }

      .k-phone {
        color: $k-yellow;
        font-family: 'roboto', serif;
        text-decoration: none;
      }

      .k-active-hours {
        text-align: end;
        color: $k-yellow;
        font-family: 'roboto', serif;
      }

      .k-social {
        float: left;
        margin-left: 10px;
        color: $k-yellow;
        font-family: 'roboto', serif;
      }

      @media (min-width: 800px) {
        .for-mobile {
          display: none;
        }
      }
      @media (max-width: 800px) {
        .k-phone {
          span {
            display: none;
          }
        }

        .k-active-hours {
          .for-desktop {
            display: none;
          }

          .for-mobile {
            display: initial;
          }
        }
        .k-social {
          span {
            display: none;
          }
        }
      }

    }
  }

  .k-header {
    max-width: 1600px;
    width: 100%;
    position: relative;
    margin: 0 auto auto;
    height: 100%;
    align-items: center;
    justify-content: center;

    .k-home-filter {
      height: 100%;

      a {
        height: 100%;
      }
    }

    .header-menu {
      mat-icon, mat-select {
        margin-right: 25px;
        margin-left: 25px;
        color: #f9d607;
        text-decoration: none;
      }

      .language-switcher {
        width: 60px;

        a {
          text-decoration: none;
        }
      }

      mat-icon:hover {
        cursor: pointer;
        color: white;
      }
    }

    .site-logo {
      width: 150px;
    }

    .site-logo:focus {
      outline: none;
    }

    .site-logo:hover {
      cursor: pointer;
    }
  }


  .k-main-header {
    position: fixed;
    top: 0;
    z-index: 5;
    height: 60px;
    background-color: #333333;
    box-shadow: 0 0 6px #6b6868;
  }


  .k-mobile-header {
    position: fixed;
    top: 0;
    z-index: 5;
    margin-top: 60px;
    height: auto;
    background-color: #333333;
    box-shadow: 0 0 6px #6b6868;
    width: 100%;
    padding: 0;

    .k-home-filter {
      overflow: hidden;

      ul {
        max-width: 100%;
        display: flex;
        flex-wrap: wrap;
        height: 100%;
        padding: 0;

        &::-webkit-scrollbar {
          width: 8px;
          display: none;
        }
      }
    }
  }

}

.k-sidenav {
  top: 110px !important;

  .k-sidenav-container {
    padding: 24px 12px;
  }
}

@media (max-width: 700px) {
  .k-parent {
    padding-bottom: 160px;
  }
}
@media (min-width: 1350px) {
  .k-home-filter-mobile {
    display: none !important;
  }
  .k-mobile-header {
    display: none !important;
  }
  .k-main-header-categories {
    position: fixed;
    top: 60px;
    z-index: 5;
    height: 80px;
    background-color: #333333;
    box-shadow: 0 0 6px #fffefe;
  }
}

@media (max-width: 1350px) {
  .k-header {
    height: 70px !important;

    .k-home-filter {
      display: none !important;
    }
  }
  .k-main-header-categories {
    display: none !important;
  }
  .k-parent {
    padding-top: 150px !important;
  }
  .k-sidenav {
    top: 150px !important;
  }
  .k-parent {
    .k-main-header {
      .k-header {
        .header-menu {
          .k-nav-ul {
            .k-nav-li {
              padding-right: 8px;
              padding-left: 8px;
            }
          }
        }
      }

      .site-logo {
        width: 80px !important;
      }
    }
  }
  .k-mobile-header {
    margin: 0;
    padding: 0 !important;

    .k-home-filter {
      height: 100%;

      ul {
        padding: 0 !important;
        height: 100%;
      }

      .k-type-filter-item-mobile {
        height: 44px;

        a {
          height: auto;

          img {
            margin: auto;
            width: 40px;
            height: 40px;
          }

          //height: 100%;
          margin: 0 0;

          span {
            display: none;
          }
        }
      }
    }
  }
}


.k-button {
  background-color: $k-yellow;
  color: black;
  outline: black;
  border-color: $k-yellow;
  font-weight: bold;
}

.k-button:hover {
  background-color: $k-yellow;
  color: white;
  outline: black;
  border-color: $k-yellow;
  font-weight: bold;
}

.k-button:active {
  background-color: black !important;
  color: $k-yellow !important;
  outline: $k-yellow !important;
  border-color: $k-yellow !important;
  font-weight: bold !important;
}

.k-input {
  border-color: $k-yellow !important;;
}

.k-input:focus {
  box-shadow: none !important;
}

.k-footer {
  //background-color: $k-yellow;
  //outline: 1px solid #333333;
  //height: 80px;
  //vertical-align: middle;
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-bottom: 60px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  p {
    padding-top: 20px;
    color: black;
  }

  a {

    //text-decoration: none;
    color: black;
    //padding: 0 8px;

    width: fit-content;
    white-space: nowrap;
    text-decoration: none;
    //background-color: #f9d607;
    padding: 5px 10px;
    margin: 0;
    //border: 1px solid #000;
  }

}


@media screen and (min-width: 1500px) {
  .k-sidenav-container {
    min-width: 35vw;
    max-width: 40vw;
  }
}

@media screen and (max-width: 1500px) and (min-width: 1200px) {
  .k-sidenav-container {
    width: 45vw;
  }
}

@media screen and (max-width: 1200px) and (min-width: 1000px) {
  .k-sidenav-container {
    width: 50vw;
  }
}

@media screen and (max-width: 1000px) {
  .k-sidenav-container {
    width: 100vw;
  }
}

.side-nav-open {
  overflow: hidden;
}

button:focus {
  outline: none !important;
}

.mat-snack-bar-handset {
  background-color: green;
}
